import 'whatwg-fetch';
import './style.css';
import './app.css';
import {loginQuery, resetPasswordQuery} from './queries.js';
const {createApolloFetch} = require('apollo-fetch');
require('@iconify/iconify');
const isFirefox = require('is-firefox');

const fetch = createApolloFetch('/graphql');

const loginPage = document.querySelector('.js-login');
const resetPasswordPage = document.querySelector('.js-resetPassword');
const choicesPage = document.querySelector('.js-choices');
let currentLogin;


const displayMessages = (page, errors, selector = '.js-errors') => {
    const errorsElements = page.querySelectorAll(selector);

    for (let i = 0; i < errorsElements.length; i++) {
        const item = errorsElements[i];

        item.innerHTML = errors.map(({message}) => `<span>${message}</span>`).join('');
    }
};

const callApi = (query, variables = {}, page) => {
    page.querySelector('.js-submitButton').classList.add('is-loading');
    return fetch({
        query, variables
    }).then(({data = {}, errors = []}) => {
        page.querySelector('.js-submitButton').classList.remove('is-loading');
        if (errors.length) {
            displayMessages(page, errors);
            throw errors;
        } else {
            return data;
        }
    });
};


const showLogin = () => {
    displayMessages(loginPage, []);
    const usernameField = loginPage.querySelector('[name="username"]');
    const stationField = loginPage.querySelector('[name="station"]');

    const login = usernameField.value;
    const station = stationField.value;

    loginPage.querySelector('.Form').reset();

    usernameField.setAttribute('value', login);
    stationField.setAttribute('value', station);

    loginPage.querySelector('.Form').reset();
    loginPage.classList.remove('is-hidden');
    resetPasswordPage.classList.add('is-hidden');
    choicesPage.classList.add('is-hidden');

    loginPage.querySelector('[name="password"]').focus();
};

const showResetPassword = () => {
    displayMessages(resetPasswordPage, []);
    resetPasswordPage.querySelector('.Form').reset();
    loginPage.classList.add('is-hidden');
    resetPasswordPage.classList.remove('is-hidden');
    choicesPage.classList.add('is-hidden');
};

const showChoices = () => {
    loginPage.classList.add('is-hidden');
    resetPasswordPage.classList.add('is-hidden');
    choicesPage.classList.remove('is-hidden');
};

loginPage.querySelector('.Form').addEventListener('submit', e => {
    e.preventDefault();
    const vars = {
        'username': e.target.querySelector('[name="username"]').value,
        'password': e.target.querySelector('[name="password"]').value,
        'station': Number(e.target.querySelector('[name="station"]').value)
    };
    callApi(loginQuery, vars, loginPage).then(data => {
        if (data.login.needPasswordChange) {
            currentLogin = data.login.user.login;
            showResetPassword();
        } else {
            if (data.login.payload.redirect) {
                window.location = data.login.payload.redirect;
            } else {
                if (data.login.payload.whitelist.failed) {
                    const whitelistMessage = data.login.payload.whitelist.message;
                    displayMessages(choicesPage, [{message: whitelistMessage}], '.js-messages');
                } else {
                    displayMessages(choicesPage, []);
                }
                const legacyLink = choicesPage.querySelector('.js-2bevoipLink');
                legacyLink.setAttribute('href', data.login.payload.options.legacy);
                const applicationLink = choicesPage.querySelector('.js-exovoipLink');
                applicationLink.setAttribute('href', data.login.payload.options.application);
                showChoices();
            }

        }
    });
});

resetPasswordPage.querySelector('.Form').addEventListener('submit', e => {
    e.preventDefault();
    const vars = {
        'login': currentLogin,
        'oldPassword': e.target.querySelector('[name="old-password"]').value,
        'newPassword': e.target.querySelector('[name="new-password"]').value,
        'confirmPassword': e.target.querySelector('[name="confirm-password"]').value
    };
    callApi(resetPasswordQuery, vars, resetPasswordPage).then(() => {
        displayMessages(loginPage, [{message: 'Password changed, please log again'}], '.js-messages');
        showLogin();
    });
});


const isSilverlightAvailable = () => {
    const SilverRegex = new RegExp('Silverlight');
    const plugins = navigator.plugins;

    if (navigator && plugins) {
        for (let i = 0; i < plugins.length; i++) {
            if (plugins.hasOwnProperty(i) && SilverRegex.exec(plugins[i].name)) {
                return true;
            }
        }
    }
    return false;
};

const isCustomElementsAvailable = () => {
    const hasCustomElements = window.customElements !== undefined;
    return hasCustomElements || isFirefox;
};

if (!isSilverlightAvailable()) {
    const legacyLink = choicesPage.querySelector('.js-2bevoipLink');
    legacyLink.classList.add('is-disabled');

    const stationField = loginPage.querySelector('[name="station"]');
    stationField.hidden = true;
}

if (!isCustomElementsAvailable()) {
    const applicationLink = choicesPage.querySelector('.js-exovoipLink');
    applicationLink.classList.add('is-disabled');
}
